import * as React from "react";

class MarketMakingHowTo extends React.Component {
    render() {
        return (
            <section className="hg_section pt-80 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block text-center">
                                <h3 className="tbk__title fs-xxl fw-thin"><span
                                    className="fw-semibold fs-xxxl fs-xs-xl tcolor">Market Making As A Service</span> on Crypto with BitSpreader
                                </h3>

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 mb-md-30 mt-lg-50">
                            <div className="kl-title-block">
                                <h5 className="fs-normal fw-bold light-gray">
                                    Features
                                </h5>

                                <h2 className="fs-l reset-line-height fw-normal mb-50">
                                    <strong>BitSpreader as Cryptocurrency Market Making Platform</strong>
                                </h2>
                            </div>

                            <div className="text_box">

                                <ul className={"list-style1"}>
                                    <li>Define levels & spreads</li>
                                    <li>Hedge on other exchanges</li>
                                    <li>Live view of orders and outstanding liquidity</li>
                                    <li>Automated exposure closing</li>
                                    <li>Use of custom indicators & strategies</li>
                                    <li>Get detailed insight on your current position and exposure</li>
                                    <li>Detailed reporting</li>
                                    <li>Use shared or custom, individually tailored infrastructure</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 mb-md-30 mt-lg-50">
                            <div className="kl-title-block text-right">
                                <h5 className="fs-normal fw-bold light-gray text-right">
                                    Custom Features
                                </h5>

                                <h2 className="fs-l reset-line-height fw-normal mb-50 text-right">
                                    <strong>Work with our development team to get tailored solutions</strong>
                                </h2>
                            </div>

                            <div className="text_box ml-100">

                                <ul className={"list-style1"}>
                                    <li>Custom exchange integrations</li>
                                    <li>Custom market making strategies</li>
                                    <li>Custom indicators</li>
                                    <li>Custom reporting</li>
                                    <li>Custom, individually tailored infrastructure</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        );

    }
}

export default MarketMakingHowTo;