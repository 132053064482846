import * as React from "react"
import '../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../css/bootstrap.css";
import "../fonts/font-awesome/css/font-awesome.min.css";
import "../css/template.css";
import "../css/responsive.css";
import "../css/base-sizing.css";
import "../css/custom.css";

import HeaderAndMenu from "../page_components/common/HeaderAndMenu";
import Footer from "../page_components/common/Footer";
import BsHelmet from "../page_components/common/BsHelmet";
import TradingTerminal from "../page_components/services/TradingTerminal";
import CommonHero from "../page_components/common/CommonHero";
import CloudBasedSolution from "../page_components/services/CloudBasedSolution";
import OrderTypes from "../page_components/services/OrderTypes";
import PositionManagement from "../page_components/services/PositionManagement";
import AdvancedChart from "../page_components/services/AdvancedChart";
import SecuritySection from "../page_components/common/SecuritySection";
import SpreadMatrix from "../page_components/services/SpreadMatrix";
import GeoColocation from "../page_components/common/GeoColocation";
import MarketMakingTerminal from "../page_components/services/MarketMakingTerminal";
import MarketMakingHowTo from "../page_components/services/MarketMakingHowTo";


const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"MARKET MAKING ON CRYPTO AS A SERVICE"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHero title="MARKET MAKING AS A SERVICE ON CRYPTO"/>
                <MarketMakingTerminal/>
                <MarketMakingHowTo/>
                <CloudBasedSolution/>
                <GeoColocation/>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default IndexPage
