import * as React from "react";

class MarketMakingTerminal extends React.Component {
    render() {
        return (
            <section className="hg_section pt-80 pb-100 bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block text-center">
                                <h3 className="tbk__title fs-xxl fw-thin">Market Making <span
                                    className="fw-semibold fs-xxxl fs-xs-xl tcolor">As A Service</span>
                                </h3>

                                <h3 className="tbk__title">
                                    Technology service for token issuers & market makers
                                </h3>

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 mb-md-30 mt-lg-50">

                            <div className="kl-title-block">
                                <h5 className="fs-normal fw-bold light-gray">
                                    Intro
                                </h5>

                                <h2 className="fs-l reset-line-height fw-normal mb-50">
                                    <strong>What is Market Making as a service?</strong>
                                </h2>
                            </div>

                            <div className="text_box">
                                <p className="text-justify">
                                    <span className='fw-semibold'>Market Making as a Service (MMaaS)</span> is a technology service that enables token issuers to set their strategies in market making, allowing them to trade and manage their own liquidity. It is also service for other market makers to grow their reach by accessing more markets through third party platform.
                                    Traditional market makers provide liquidity with a mix of tokens loaned from the projects' and their own funds to create trading pairs. They do market making on their own terms and retain the profits from these activities.
                                    With MMaaS, token projects use their own tokens and funds to provide liquidity with the MMaaS technology. Projects get control of the strategies and the potential rewards from the market-making activity in exchange for a retainer to use the technology.
                                </p>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="el-devimages el-devimages--type-img el-devimages--ltr">
                                <div className="el-devimages__laptop">
                                    <div className="el-devimages__laptop-img"
                                         style={{backgroundImage: "url(/staticimg/screenshots/making.png)"}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        );

    }
}

export default MarketMakingTerminal;